<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>评论</el-breadcrumb-item>
            <el-breadcrumb-item>批量添加</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="批量添加数量">
          <el-input v-model="form.addnum" type="number"></el-input>最好小于200以内
        </el-form-item>
        <el-form-item label="商品ID" :error="$v.form.productid.$error ? '请输入商品ID' : ''">
          <el-input v-model="form.productid" type="number"></el-input>
        </el-form-item>
        <el-form-item label="评论内容" :error="$v.form.commentcontents.$error ? '请输入评论内容' : ''">
          <el-input v-model="form.commentcontents" type="textarea" maxlength="200" show-word-limit :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="评论开始时间">
          <el-date-picker v-model="form.time1" type="datetime" placeholder="选择日期时间" align="right"
            format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="评论结束时间">
          <el-date-picker v-model="form.time2" type="datetime" placeholder="选择日期时间" align="right"
            format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/tinymce";
import ImageUpload from "@/components/imageUpload";
import ImagesUpload from "@/components/imagesUpload";
import FileUpload from "@/components/fileUpload";
import { editbatch, getdatilRequest } from "@/api/zr_productcomment";

import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  name: "Articletypeedit",
  data() {
    return {
      classify: [],
      shopdata: [],
      form: {
        id: 0,
        producttypeid: "",
        homeImgarray: []
      },
      producttypeidtext: [],
      educationdata: [],
      constellationdata: [],
      zodiacdata: [],
      typeId: 0
    };
  },
  components: {
    "image-upload": ImageUpload,
    "images-upload": ImagesUpload,
    "file-upload": FileUpload,
    tinymce: Tinymce
  },
  created() {
    this.form.id = this.$route.query["id"];
    this.typeId = this.$route.query["typeId"];

    this.getDetail();
  },
  methods: {
    back() {
      this.$router.back();
    },
    bannerUpload(url) {
      console.log(url);
      this.form.commentimgs = url;
    },
    imageUpload(url) {
      this.form.commentphoto = url;
    },
    imageUploadistoplargeimg(url) {
      this.form.istoplargeimg = url;
    },

    getSelect() {
      getSelectRequest().then(res => {
        console.log(res);
        this.classify = res.data;
      });
    },
    getSelectshop() {
      getSelectshopRequest().then(res => {
        this.shopdata = res.data;
      });
    },
    getDetail() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        res.data.commentimgs = utils.ImgListFormat(res.data.commentimgs);

        this.form = res.data;
      });
    },

    save() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));


        editbatch(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      commentcontents: {
        required
      },

      productid: { required },

    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
